
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import FileManager from './FileManager';
import Header from './Header';
import Footer from './Footer';

const FirebaseUI = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      setUserEmail(user?.email || '');
      setDisplayName(user?.displayName || '');
    });
    return () => unregisterAuthObserver();
  }, []);

  const handleSignIn = async () => {
    try {
      const provider = new firebase.auth.OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        prompt: 'select_account',
        tenant: process.env.REACT_APP_FIREBASE_TENANT_ID,
      });
      const result = await firebase.auth().signInWithPopup(provider);
      const { user } = result;
      setIsSignedIn(!!user);
      setUserEmail(user?.email || '');
      setDisplayName(user?.displayName || '');
    } catch (error) {
      console.log('Error signing in:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await firebase.auth().signOut();
      setIsSignedIn(false);
      setUserEmail('');
      setDisplayName('');
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };
  return (
<div className="auth-container">
      {!isSignedIn ? (
        <div className="main-content">
          <div className="user-profile-widget-box-parent">
          <Header />
          <button className="sign-in-button" onClick={handleSignIn}>
            Sign In
          </button>
          </div>
          <Footer />
          </div>
      ) : (
        <div className="main-content">
          <div className="user-profile-widget">
          <Header />
          <div className="user-profile-widget-box-parent">
          <div className="user-profile-widget-box box-profile">
            <div className="user-profile-info">
            <h3 className="display-name">{displayName}</h3>
              <h3 className="user-email">{userEmail}</h3>
              {/* Add additional user profile information as needed */}
            </div>
            <button className="sign-out-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
          </div>
          </div>
          <FileManager />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default FirebaseUI;