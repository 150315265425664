import React from 'react';
import Logo2 from './PSSIG_Logo2.png';

const Header = () => {
  return (
  <header className="header font-all">
    <div className="header-flex font-all">
      <div className="logo">
        <img src={Logo2} alt="PSSI Group Logo" />
      </div>
      <div className="header-content">
      <h1 className="title">Equipment Certificate Repository</h1>
    </div>
    </div>
  </header>
  );
};

export default Header;
