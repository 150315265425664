import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      &copy; 2023 PSS Industrial Group
    </footer>
  );
};

export default Footer;
