import React from 'react';
import FirebaseUI from './FirebaseUI';


const CertRepo = () => {
  return (
    <div className="repository margin-parent">
      <FirebaseUI />
    </div>
  );
};

export default CertRepo;
