import React from 'react';
import ReactDOM from 'react-dom';
import { UserProvider } from './UserContext';
import AppRouter from './AppRouter';
import './styles.css';

ReactDOM.render(
  <UserProvider>
    <AppRouter />
  </UserProvider>,
  document.getElementById('root')
);
