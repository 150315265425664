import React, { useEffect, useState } from 'react';
import { firestore } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';


const FileManager = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [readyToUpload, setReadyToUpload] = useState(false);
  const [fileData, setFileData] = useState([]);
  const currentUser = firebase.auth().currentUser;
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchFileData = async () => {
      if (currentUser) {
        const snapshot = await firestore
          .collection('certificates')
          .where('authoredByUID', '==', currentUser.uid)
          .orderBy('uploadDate', 'desc')
          .limit(5)
          .get();
        const data = snapshot.docs.map((doc) => doc.data());
        setFileData(data);
      }
    };

    if (currentUser) {
      fetchFileData();
    }
  }, [currentUser]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setUploadProgress(0);
    setUploadComplete(false);
    setReadyToUpload(true); // Set readyToUpload to true when a new file is selected
  };

  const handleUpload = () => {
    if (selectedFile) {
      setUploadComplete(false)
      setReadyToUpload(false); // Set readyToUpload to false when upload starts

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(selectedFile.name);

      const uploadTask = fileRef.put(selectedFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setUploadComplete(true);
            console.log('File uploaded successfully!', downloadURL);

            const responseHeaders = {
              'Content-Type': 'application/pdf',
              'Content-Disposition':'inline',
            };

            const firestore = firebase.firestore();
            firestore
              .collection('certificates')
              .add({
                filename: selectedFile.name,
                downloadURL: downloadURL,
                uploadDate: new Date().toISOString(),
                createdBy: firebase.auth().currentUser.displayName,
                authoredByUID: firebase.auth().currentUser.uid,
                fileExtension: selectedFile.name.split('.').pop(),
                responseHeaders: responseHeaders,
              })
              .then(() => {
                firestore
                  .collection('certificates')
                  .where('authoredByUID', '==', currentUser.uid) 
                  .orderBy('uploadDate', 'desc')
                  .limit(5)
                  .get()
                  .then((snapshot) => {
                    const data = snapshot.docs.map((doc) => doc.data());
                    setFileData(data);
                  });
              });
          });
        }
      );
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.log('Copy failed:', error);
      });
  };

  return (
    <div className="file-manager-container font-all">
    <h1 className="title">QR Code Link Generator</h1>
    <div className="file-manager font-all">
      <div className="file-uploader">
        <h2 className="file-uploader-header">File Upload</h2>
        <input type="file" onChange={handleFileChange} />
        <button className="upload-button" onClick={handleUpload}>
            Upload File
          </button>
        {readyToUpload && !uploadComplete && (
          <span style={{ color: 'blue' }}>Ready to upload</span>
        )}
        {uploadProgress > 0 && <progress value={uploadProgress} max="100" />}
        {uploadComplete && (
          <div className="upload-success">
            ✓ File uploaded successfully!
          </div>
        )}
      </div>

      <div className="file-browser">
        {copySuccess && <div className="copy-success">Copied to clipboard!</div>}
        <table className="file-table">
          <thead>
            <tr>
              <th>Filename</th>
              <th>Upload Date</th>
              <th>Created By</th>
              <th>File Extension</th>
              <th>Download URL</th>
            </tr>
          </thead>
          <tbody>
            {fileData.map((file) => (
              <tr key={file.filename} className="table-row">
                <td>{file.filename}</td>
                <td>{new Date(file.uploadDate).toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                 })}</td>
                <td>{file.createdBy}</td>
                <td>{file.fileExtension}</td>
                <td>
                  <span
                    className="download-url"
                    onClick={() => copyToClipboard(file.downloadURL)}
                  >
                    {file.downloadURL}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};

export default FileManager;
