import { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userEmail, setUserEmail] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);

  return (
    <UserContext.Provider value={{ userEmail, setUserEmail, isSignedIn, setIsSignedIn }}>
      {children}
    </UserContext.Provider>
  );
};
