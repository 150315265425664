import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';


// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig); 


// Azure AD provider configuration
// Create a new instance of the OAuthProvider class provided by the Firebase Authentication SDK and assign it to a variable named provider
const provider = new firebase.auth.OAuthProvider('microsoft.com'); 
provider.setCustomParameters({
  prompt: process.env.REACT_APP_FIREBASE_PROMPT_ID, // Azure App ID
  tenant: process.env.REACT_APP_FIREBASE_TENANT_ID // Azure AD tenant ID
});

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage().ref();
export const azureADProvider = provider;